export default {
    "chat": {
        "press_name": "Введите имя",
        "new_chat": "Новый диалог / Групповой чат",
        "chats": "Чаты",
        "contacts": "Контакты",
        "create_meeting": "Создать видео конференцию",
        "admin": "Администратор",
        "press_message": "Введите сообщение",
        "participant": "участник",
        "participant2": "участника",
        "participant3": "участников",
        "error": "Ошибка",
        "owner": "Автор",
        "to_answer": "Ответить",
        "remove": "Удалить",
        "set_task": "Поставить задачу",
        "personal_chat": "Личный чат",
        "file_and_image": "Файл или изображение",
        "systemic": "Системное",
        "chat_information": "Информация о чате",
        "chat_delete": "Удаление чата",
        "chat_delete_text": "Вы действительно хотите удалить чат?",
        "yes": "Да",
        "no": "Нет",
        "close": "Закрыть",
        "edit": "Изменить имя",
        "add_user": "Добавить участника",
        "uncover": "Развернуть",
        "collapse": "Свернуть",
        "moderate": "Модератор",
        "pick_up_a_moderator": "Забрать модератора",
        "submit_a_moderator": "Дать модератора",
        "exclude": "Исключить",
        "file_max_count": "В сообщении можно отправить только {count} файлов",
        "file_size_error": "Файл {name} превышает {filesize} мегабайт!",
        "file_modal_title": "Отправить собщение с файлом",
        "send": "Отправить",
        "add_task": "Добавить задачу",
        "select_date": "Выбрать дату",
        "select_performer": "Выбрать исполнителя",
        "author": "Автор",
        "select_author": "Выбрать автора",
        "observers": "Наблюдатели",
        "actions": "Действия",
        "add": "Добавить",
        "chat_message": "Сообщения в чате",
        "comment": "Комментария",
        "create_task": "Создать задачу",
        "change": "Изменить",
        "anchor": "Закрепить",
        "unpin": "Открепить",
        "pinned_message": "Закрепленное сообщение #{index}",
        "pinned_messages": "Закрепленные сообщения",
        "unpin_all": "Открепить все",
        "show_in_chat": "Показать в чате",
        "moderator": "Модератор",
        "create_group_chat": "Создание группового чата",
        "chat_name": "Название чата",
        "chat_conference": "Конференция чата {name}",
        "task_conference": "Конференция задачи {name}",
        "task_list": "Задачи",
        "chat_files": "Файлы чата",
        "project_files": "Файлы проекта",
        "delete_chat": "Удалить чат",
        "delete_chat_mess": "Чат удален",
        "chat_delete_confirm": "Вы действительно хотите удалить этот чат?",
        "save": "Сохранить",
        "name_changed": "Название чата изменено",
        "change_chat_name": "Изменить название группового чата",
        "press_chat_name": "Введите название чата",
        "member_added": "Участник добавлен",
        "chat_administration_excluded_you": "Администрация чата исключила вас",
        "select_chat": "Выберите чат <br/>или ",
        "select_chat2": "Выберите чат",
        "create_new_chat": "создайте новую беседу",
        "field_require": "Обязательно для заполнения",
        "field_min_require": "Минимум {min} символов",
        "field_max_require": "Максимум {max} символов",
        "field_email_recuire": "E-mail заполнен не верно",
        "wrong_email_or_password": "Неправильный логин или пароль",
        "press_user_name": "Введите имя пользователя",
        "user_name": "Имя пользователя",
        "group_chat_add": "Добавить пользователя в чат",
        "personal_dialogue": "Личный диалог",
        "chat_author": "Автор чата:",
        "chat_author_can_rename_chat": "Автор чата может переименовать чат",
        "chat_author_can_delete_chat": "Автор чата может удалить чат",
        "chat_author_can_remove_chat_participants": "Автор чата может удалять участников чата",
        "chat_menu": "Автор чата и модераторы могут добавлять новых участников через меню",
        "chat_menu2": "в шапке сайта или нажатием на название чата",
        "chat_author_can_assign_remove_moderators": "Автор чата может назначать/удалять модераторов",
        "chat_info_max": "Максимальная длина сообщения 1024 символов",
        "chat_info_del": "Сообщения можно удалять в пределах 1 минуты после публикации",
        "chat_info_file_size": "Максимальный вес файла 10МБ",
        "chat_info_enter": "Быстрая отправка сообщений через клавишу Enter",
        "chat_creator_and_moderators_can_delete_chat_messages": "Автор чата и модераторы могут удалять сообщения в чате",
        "chat_info_news_str": "Для перехода на новую строку используйте сочетание клавиш Shift+Enter",
        "not_fount": "Пользователь не найден",
        "enter_your_message": "Введите сообщение",
        "attach": "Прикрепить",
        "attached_file": "Прикрепленный файл",
        "dead_line": "Крайний срок",
        "priority": "Приоритет",
        "operator": "Ответственный",
        "project": "Проект",
        "workgroup": "Команда",
        "name_ru": "Название",
        "conference": "Конференция",
        "comments_sent": "Комментарий отправлен",
        "open_task": "Открыть задачу",
        "create_and_open": "Сохранить и открыть",
        "create_and_create": "Сохранить и создать еще одну",
        "create_and_copy": "Сохранить и скопировать",
        "change2": "Изменение",
        "task2": "Задача",
        "history": "История",
        "select_task": "Выбрать задачу",
        "task_name": "Название задачи",
        "no_time_limit": "Без срока",
        "edit_task": "Редактировать задачу",
        "change_moderator": "Назначить модератором",
        "share_to_chat": "Поделиться в чат",
        "task_sent": "Задача отправлена",
        "file_sent": "Файл отправлен",
        "tasks": "Задачу",
        "share": "Поделиться",
        "copy_link": "Скопировать ссылку",
        "share_telegram": "Отправить в Telegram",
        "share_whatsapp": "Отправить в WhatsApp",
        "share_email": "Отправить на E-mail",
        "chat_task": "Задачи в чате",
        "crm.goodsordermodel": "Заказ",
        "give_access_to_files": "Предоставить доступ к файлам",
        "more": "Подробнее",
        "set_order": "Создать заказ"
    }
}